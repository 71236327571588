<template>
  <v-img src="@/assets/back.jpg" :height="$vuetify.breakpoint.xl ? height:''">
    <div class="text-center mb-12 mt-16">
      <p class="text-h4 orange--text darken-3"
         style="padding-top: 60px;display: inline;user-select: none;">致开科技</p>
      <p class="font-weight-light mt-2" style="user-select: none">
        深圳市致开科技有限公司，是一家以电子信息技术应用为核心，集自主研发，生产，服务为一体的科技创新企业。</p>
    </div>
    <v-row class="mb-16">
      <v-col lg="6" cols="12">
        <v-hover v-slot="{ hover }">
          <div>
            <!-- $vuetify.breakpoint.width 代表屏幕宽度-->
            <v-card :class="$vuetify.breakpoint.width < 1441 ? 'mx-auto':'float-right'"
                    elevation="0">
              <v-img src="https://cdn.chikytech.com.cn/images/video/img02.png"></v-img>
              <!--              <video class="v-deep" src="https://cdn.chikytech.com.cn/images/video/index-video2.mp4" width="700"-->
              <!--                     height="500"-->
              <!--                     autoplay loop muted :controls="false"></video>-->
              <!-- 灰色遮罩-->
              <v-fade-transition>
                <div v-if="hover"
                     class="black v-card--reveal"
                     style="height: 100%;opacity: .6;">
                  <!-- position: absolute;top: 40%;left: 46%; 是相对于父div位置来说的-->
                  <!-- 白色的播放按钮-->
                  <v-btn style="z-index: 999;position: absolute;top: 40%;left: 46%" large outlined fab color="white"
                         @click="pmjView">
                    <v-icon dark>
                      mdi-play
                    </v-icon>
                  </v-btn>
                </div>
              </v-fade-transition>
              <v-hover v-slot="{ hover1 }">
                <!-- $vuetify.breakpoint.width 代表屏幕宽度-->
                <v-btn :id="$vuetify.breakpoint.width < 600 ?'btn1Min':'btn1'" outlined
                       href="https://inkjet.chikytech.com.cn">
                  喷码标识
                  <v-icon dark small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-hover>

            </v-card>
            <!--            注释的代码是测喷码机文字，原来每个视频底下都有对应的文字-->
            <!--            <div class="mb-16 text-center pt-5" :class="$vuetify.breakpoint.width < 1441 ? 'mx-auto':'float-left'"-->
            <!--                 style="width: 700px">-->
            <!--              <p class="text-h5" style="clear: left;user-select: none">喷码机</p>-->
            <!--              <v-expand-x-transition>-->
            <!--                <v-sheet width="100" height="3" color="orange darken-3" class="rounded-xl mx-auto mt-2"-->
            <!--                         v-show="hover"></v-sheet>-->
            <!--              </v-expand-x-transition>-->
            <!--            </div>-->
          </div>
        </v-hover>
      </v-col>
      <v-col lg="6" cols="12">
        <v-hover v-slot="{ hover }">
          <div>
            <v-card :class="$vuetify.breakpoint.width < 1441 ? 'mx-auto':'float-left'"
                    elevation="0">
              <v-img src="https://cdn.chikytech.com.cn/images/video/img01.png"></v-img>
              <!--              原本的视频展示+自动播放-->
              <!--              <video class="v-deep" src="https://cdn.chikytech.com.cn/images/video/index-video1.mp4" width="700"-->
              <!--                     height="500" autoplay loop muted :controls="false">-->
              <!--              </video>-->
              <!-- 灰色遮罩-->
              <v-fade-transition>
                <div v-if="hover"
                     class="black v-card--reveal"
                     style="height: 100%;opacity: .6;">
                  <!-- position: absolute;top: 40%;left: 46%; 是相对于父div来说的位置-->
                  <!-- 白色的播放按钮-->
                  <v-btn style="z-index: 999;position: absolute;top: 40%;left: 46%;" large fab outlined color="white"
                         @click="cmyView">
                    <v-icon dark>
                      mdi-play
                    </v-icon>
                  </v-btn>
                </div>
              </v-fade-transition>
              <v-btn :id="$vuetify.breakpoint.width < 600 ?'btn1Min':'btn1'" outlined
                     href="https://gps.chikytech.com.cn">
                定位测量
                <v-icon dark small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-card>
          </div>
        </v-hover>
      </v-col>
    </v-row>
    <!--    点击视频播放弹窗-->
    <v-dialog v-model="dialog" width="720">
      <v-card>
        <div style="background-color: rgb(255,152,0); text-align: right">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <!--  preload="auto" 自动预加载视频 -->
        <vue-core-video-player preload="auto" loop=true :src="videoSrc"></vue-core-video-player>
      </v-card>
    </v-dialog>
  </v-img>
</template>

<script>
import HLSCore from '@core-player/playcore-hls';

export default {
  name: "VideoView",
  data: () => ({
    dialog: false,
    videoSrc: '',
    height: 0,
    HLSCore,
  }),
  methods: {
    cmyView() {
      this.videoSrc = 'https://chikytech.oss-cn-shenzhen.aliyuncs.com/video/index-video1.mp4';
      this.dialog = true;
    },
    pmjView() {
      this.videoSrc = 'https://chikytech.oss-cn-shenzhen.aliyuncs.com/video/index-video2.mp4';
      this.dialog = true;
    }
  },
  created() {
    this.height = window.innerHeight - 130
    window.onresize = () => {
      this.height = window.innerHeight - 130
    }
  }
}
</script>

<style scoped>
/*标准情况下的button按钮样式*/
#btn1 {
  color: white;
  z-index: 999;
  position: absolute;
  top: 90%;
  left: 80%;
}

/*手机小屏幕情况下的button按钮样式*/
#btn1Min {
  color: white;
  z-index: 999;
  position: absolute;
  top: 80%;
  left: 65%;
}

/*标准情况下的button按钮浮动的样式*/
#btn1:hover {
  color: #E15009;
  z-index: 999;
  position: absolute;
  top: 90%;
  left: 80%;
}

/*手机小屏幕情况下的button按钮浮动的样式*/
#btn1Min:hover {
  color: #E15009;
  z-index: 999;
  position: absolute;
  top: 80%;
  left: 65%;
}


.v-deep {
  object-fit: cover;
}

.v-card--reveal {
  top: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
